import switchLanguage from "./js/switchLanguage";

window.switchLanguage = switchLanguage;

const toggleDisclaimer = () => {
  if (location.hash === "#disclaimer") {
    const disclaimerElement = document.getElementById("disclaimer");
    disclaimerElement.style.display = "block";
  } else {
    const disclaimerElement = document.getElementById("disclaimer");
    disclaimerElement.style.display = "none";
  }
};

let prevTop = window.scrollY;

const navigate = (target) => {
  let top = 0;
  switch (target) {
    case "#realtime":
      top = document.getElementById("realtime").offsetTop;
      break;
    case "#gameplay":
      top = document.getElementById("gameplay").offsetTop + 300;
      break;
    case "#support":
      top = document.getElementById("support").offsetTop;
      break;
    case "#disclaimer":
      const offset = window.innerWidth > 1024 ? 100 : 48;
      top = document.getElementById("disclaimer").offsetTop - offset;
      break;
  }

  window.scroll({ top: prevTop });

  setTimeout(() => {
    window.scroll({
      top: top,
      behavior: "smooth",
    });
  }, 5);
};

window.addEventListener("load", () => {
  toggleDisclaimer();
  switchLanguage("zh_CN");
  navigate(location.hash);
});

window.addEventListener("scroll", function () {
  const targetLogo = document.querySelector(".target-logo");

  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  if (scrollTop > 100 && scrollTop < 350) {
    document.querySelector("nav").className = "ready";
  } else if (scrollTop >= 350 && scrollTop <= 650) {
    document.querySelector("nav").className = "hide";
  } else if (scrollTop > 650) {
    document.querySelector("nav").className = "sticky";
    targetLogo.className = "target-logo show";
  } else {
    document.querySelector("nav").className = "";
    targetLogo.className = "target-logo";
  }
});

window.addEventListener("hashchange", () => {
  toggleDisclaimer();
  navigate(location.hash);
});

document.querySelectorAll("a").forEach((element) => {
  element.addEventListener("click", () => {
    prevTop = window.scrollY;
  });
});
