export default {
  TITLE: '乐掏金',
  DISCLAIMER_CONTENT: `
    <p>乐掏金只提供投注服务。本网站中的信息不包含（也不应被解释为包含）任何金融工具的投资建议或投资推荐，或交易要约或请求。过去的表现并不代表未来的结果。乐掏金对所提供信息的任何使用途径以及由此产生的任何后果概不负责。</p>
    <p>通过乐掏金平台进行的交易仅用于娱乐博彩目的，不构成投资或收购任何资产。损失的风险可能是巨大的，您赌注的价值可能会波动。对资产价格的押注受到一系列复杂因素的影响，并伴随着快速亏钱的高风险，特别是在价差押注或对期货的其他押注方面，在这些押注中，您的损失可能超过您原来的筹码。外汇汇率的变化也可能影响您的赌注价值。你应该考虑一下你是否了解这个产品是如何运作的，以及你是否能够承担损失金钱的风险。</p>
    <p>乐掏金平台的客户对给定赌注的价格是参照相关基础金融工具的价格计算的。此价格由乐掏金从独立的第三方参考来源获得，任何价格的最终确定应由乐掏金自行决定，除非出现明显错误。乐掏金会在技术和互联网连接的限制下，尽可能高频次地更新其价格。</p>
    <p>不符合乐掏金操作的价差限制的价格源不由乐掏金提供，因此不会呈现给客户，直到它们在乐掏金可接受的现行市场价差的范围内。交易量持续低迷且市场价值不佳的工具通常不会用于交易。</p>
    <p>此处的信息不针对任何国家或地区的任何人。</p>
  `
}
