export default {
  TITLE: 'finGame',
  DISCLAIMER_CONTENT: `
  <p>
    FinGame provides a betting only service. The information in this
    site does not contain (and should not be construed as containing)
    investment advice or an investment recommendation, or an offer of
    or solicitation for transaction in any financial instrument. Past
    performance is not indicative of future results. FinGame accepts
    no responsibility for any use that may be made of the information
    provided and for any consequences that result.
  </p>
  <p>
    Transactions effected through the FinGame platform are intended
    purely for recreational betting purposes and do not constitute
    investments or the acquisition of any assets. The risks of loss
    can be substantial and the value of your bets may fluctuate. Bets
    on asset prices are subject to an array of complex factors and
    come with a high risk of losing money rapidly particularly in
    respect of spread betting or other wagering on futures in which
    you can lose more than your original stake. Changes in foreign
    currency exchange rates may also affect the value of your bets.
    You should consider whether you understand how this product works,
    and whether you can afford to take the risk of losing your money.
  </p>
  <p>
    The price for a given bet made by customers of the FinGame
    platform is calculated by reference to the price of the relevant
    underlying financial instrument. This price is obtained by Fingame
    from independent third party reference sources and any final
    determination of a price shall be at the sole discretion of
    FinGame save in the event of manifest and obvious error. FinGame
    updates its prices as frequently as the limitations of technology
    and internet connectivity allow.
  </p>
  <p>
    Price feeds that do not comply to spread limitations operated by
    FinGame are not provided by the FinGame and therefore are not
    presented to customers, until they are within variance of the
    prevailing market spread acceptable to FinGame. Instruments with
    continual low trading volumes and poor market value will not
    generally be available to trade.
  </p>
  <p>
    The information herein is not directed at any person in any
    country or jurisdiction where its distribution or use would be
    contrary to local law or regulation. The availability of the
    services and information herein should not be construed as an
    offer, solicitation or invitation to use or access the foregoing
    in any country in which such use or access is illegal. By using
    the services herein, you represent and warrant that your access to
    or use of such services and information herein is not illegal or
    prohibited by laws that are applicable to you or to persons in the
    country from which you are residing.
  </p>
  `
}
