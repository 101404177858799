import translation from "./translation";
import logoEN from "url:../img/1-introduction/logo-en.svg";
import logoCN from "url:../img/1-introduction/logo-cn.svg";

const trans = (className, translation, useHTML = false) => {
  const elements = document.querySelectorAll(className);
  elements.forEach((element) => {
    if (useHTML) {
      element.innerHTML = translation;
    } else {
      element.textContent = translation;
    }
  });
};

const futuresBtn = document.querySelector(".gameplay .btn.futures");
const longshortBtn = document.querySelector(".gameplay .btn.longshort");

// Logo
const targetLogoImg = document.querySelector(".target-logo img") || {};
const headerLogoImg = document.querySelector(".logo img") || {};

// Nav
const englishLangMob = document.querySelector(".mob-lang-en");
const chineseLangMob = document.querySelector(".mob-lang-cn");

// Introduction
const headingIntro = document.querySelector(".introduction h2 span");
const headingIntroImg = document.querySelector(".introduction h2 img");
const descriptionIntro = document.querySelector(".introduction .description");

// Realtime
const headingRealtime = document.querySelector(".realtime .heading span");
const headingRealtimeImg = document.querySelector(".realtime .heading img");
const descriptionRealtime = document.querySelector(".realtime .description");

// Gameplay
const headingGameplay = document.querySelector(".gameplay h3 span");
const headingGameplayImg = document.querySelector(".gameplay h3 img");
const descriptionGameplay = document.querySelector(".gameplay .description");

// Language
const headingLanguage = document.querySelector(".language h3 span");
const headingLanguageImg = document.querySelector(".language h3 img");

// OS
const headingOS = document.querySelector(".os h3 span");
const headingOSImg = document.querySelector(".os h3 img");

function switchLanguage(lang) {
  document.documentElement.setAttribute("lang", lang);

  document.title = translation.TITLE;
  trans('.disclaimer-content', translation.DISCLAIMER_CONTENT, true);

  if (lang === "en") {
    targetLogoImg.src = logoEN;
    headerLogoImg.src = logoEN;

    trans('.target-introduction', 'Platform');
    trans('.target-realtime', 'Real Time');
    trans('.target-gameplay', 'Game Play');
    trans('.target-support', 'Support');
    trans('.target-language', 'Language');
    trans('.target-disclaimer', 'Disclaimer');

    englishLangMob.className = "mob-lang-en active";
    chineseLangMob.className = "mob-lang-cn";

    headingIntro.className = "";
    headingIntro.innerHTML =
      '<div class="highlight">Innovative</div> Finance Trading Game';
    headingIntroImg.style.display = "none";
    descriptionIntro.textContent =
      "We have been enhancing and adding new features to the game with the latest real life trading experience through a game!";

    headingRealtime.className = "";
    headingRealtime.innerHTML = '<div class="highlight">Live</div> Market Data';
    headingRealtimeImg.style.display = "none";
    descriptionRealtime.textContent =
      "We provide a wide range of product (Stocks and Indices from China, Hong Kong, US, Europe and much more!). Through our live trading data, players are engaged with an exciting real time trading experience.";

    headingGameplay.className = "";
    headingGameplay.innerHTML =
      '<div class="highlight">Brand New </div>Gameplay Experience';
    headingGameplayImg.style.display = "none";
    descriptionGameplay.textContent =
      "Game play is simple to learn and able to engage new players easily. You no longer need to know complex financial trading knowledge in order to start trading!";
    futuresBtn.textContent = "FUTURES";
    longshortBtn.textContent = "LONG SHORT";

    headingLanguage.className = "";
    headingLanguage.textContent = "Language Support";
    headingLanguageImg.style.display = "none";

    headingOS.className = "";
    headingOS.textContent = "Operating System";
    headingOSImg.style.display = "none";

    trans('.disclaimer-title', 'Disclaimer');
  }

  if (lang === "zh_CN") {
    targetLogoImg.src = logoCN;
    headerLogoImg.src = logoCN;

    chineseLangMob.className = "mob-lang-cn active";
    englishLangMob.className = "mob-lang-en";

    trans('.target-introduction', '交易平台');
    trans('.target-realtime', '实时数据');
    trans('.target-gameplay', '全新玩法');
    trans('.target-support', '支持');
    trans('.target-language', '语言');
    trans('.target-disclaimer', '免责声明');

    headingIntro.className = "sr-only";
    headingIntro.textContent = "开创性交易平台";
    headingIntroImg.style.display = "block";
    descriptionIntro.textContent =
      "一直以来，我们不断加以改善，为平台添加新的功能，产品和工具，从而为您提供极致的交易体验。";

    headingRealtime.className = "sr-only";
    headingRealtime.textContent = "庞大实时数据";
    headingRealtimeImg.style.display = "block";
    descriptionRealtime.textContent =
      "我们提供庞大的产品选择范围（中国大陆，香港，美国以及欧洲地区），通过实时市场数据，新颖刺激的游戏玩法满足交易者的需求。";

    headingGameplay.className = "sr-only";
    headingGameplay.textContent = "首创全新玩法";
    headingGameplayImg.style.display = "block";
    descriptionGameplay.textContent =
      "首创简单易懂的全新玩法，搭配止赢止损功能助您控制风险，轻松上手完成交易。";
    futuresBtn.textContent = "期货玩法";
    longshortBtn.textContent = "两面玩法";

    headingLanguage.className = "sr-only";
    headingLanguage.textContent = "支持语言";
    headingLanguageImg.style.display = "block";

    headingOS.className = "sr-only";
    headingOS.textContent = "支持系统";
    headingOSImg.style.display = "block";

    trans('.disclaimer-title', '免责声明');
  }
}

export default switchLanguage;
