import en from './en';
import zhCN from './zh_CN';

const translation = en;

const getLang = () => {
  return document.documentElement.getAttribute('lang') || 'en';
}

Object.entries(en).forEach(([key, value]) => {
  Object.defineProperty(en, key, {
    enumerable: true,
    configurable: true,
    get: () => {
      const lang = getLang();
      if (lang === 'zh_CN') {
        return zhCN[key];
      }

      return value;
    }
  })
});

export default translation;
